let wasTouched = false;
document.addEventListener("DOMContentLoaded", function () {
    let departmentsData;
    let specialtiesData;
    const contentDiv = document.querySelector("#servicesContent");
    const buttonsDiv = document.querySelector("#servicesButtons");

    const servicesMenuItem = document.getElementById("servicesMenuItem");
    const servicesDropdown = document.createElement("div");
    servicesDropdown.className = "nav-services-dropdown";
    if(servicesMenuItem === null) {
        return
    }
    servicesMenuItem.appendChild(servicesDropdown);

    function loadData() {
        fetch("/api/specialties")
            .then((response) => response.json())
            .then((data) => (specialtiesData = data.data))
            .catch((error) => console.error("Error on fetch: ", error));
    }

    function loadDepartments() {
        fetch("/api/departments")
            .then((response) => response.json())
            .then((data) => {
                departmentsData = data.data;
            })
            .catch((error) =>
                console.error("Error fetching departments: ", error)
            );
    }

    function showData(departmentId, specialSlug) {
        const servicesList = this.querySelector(".services-list");
        servicesList.innerHTML = "";

        const filteredResults = specialtiesData.filter(
            (item) =>
                item.departmentId === departmentId &&
                (!specialSlug || item.slug === "vedenie-beremennosti-vzrosloe")
        );
        filteredResults.forEach(function (item) {
            const listItem = document.createElement("li");
            listItem.className = "services-list-item";
            const link = document.createElement("a");
            link.href = "/specialties/" + item.slug;
            link.textContent = capitalizeFirstLetter(item.name);
            listItem.appendChild(link);
            servicesList.appendChild(listItem);
        });
    }

    function handleMouseEnterOnServiceItem() {
        servicesDropdown.innerHTML = "";
        departmentsData.forEach((department) =>
            createDepartmentElement(department)
        );
    }

    function createDepartmentElement(department) {
        const deptDiv = document.createElement("div");
        deptDiv.textContent = department.name;
        deptDiv.className = "nav-services-department";
        deptDiv.setAttribute("data-department-id", department.id);

        let removeSpecialtiesTimeout = null;

        deptDiv.addEventListener("mouseenter", function () {
            clearTimeout(removeSpecialtiesTimeout);
            showSpecialtiesForDepartment(this);
        });

        deptDiv.addEventListener("mouseleave", function () {
            const specialtiesDiv = this.querySelector(
                ".nav-services-specialties"
            );
            if (specialtiesDiv) {
                removeSpecialtiesTimeout = setTimeout(() => {
                    this.removeChild(specialtiesDiv);
                }, 300);
            }
        });
        servicesDropdown.appendChild(deptDiv);
    }

    function showSpecialtiesForDepartment(deptDiv) {
        const deptId = parseInt(deptDiv.getAttribute("data-department-id"), 10);
        const filteredSpecialties = specialtiesData.filter(
            (spec) => spec.departmentId === deptId
        );
        const specDiv = document.createElement("div");
        specDiv.className = "nav-services-specialties";
        setTimeout(() => {
            specDiv.classList.add("open");
        }, 0);
        filteredSpecialties.forEach((specialty) => {
            const link = document.createElement("a");
            link.href = "/specialties/" + specialty.slug;
            link.textContent = capitalizeFirstLetter(specialty.name);
            specDiv.appendChild(link);
        });
        deptDiv.appendChild(specDiv);
    }

    function handleMouseLeaveFromServiceItem() {
        servicesDropdown.innerHTML = "";
    }

    function capitalizeFirstLetter(string) {
        return string
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    function initializeEventListeners() {
        document.addEventListener("touchstart", function() {
            wasTouched = true;
        });

        if (!wasTouched) {
            servicesMenuItem.addEventListener("mouseenter", handleMouseEnterOnServiceItem);
            servicesMenuItem.addEventListener("mouseleave", handleMouseLeaveFromServiceItem);

            document.querySelectorAll(".services__item").forEach(function(element) {
                element.addEventListener("mouseenter", function() {
                    const departmentId = parseInt(this.getAttribute("data-department-id"), 10);
                    const specialSlug = this.getAttribute("data-slug");
                    showData.call(this, departmentId, specialSlug);
                });
                element.addEventListener("mouseleave", function() {
                    this.querySelector(".services-list").innerHTML = "";
                });
            });
        }

        servicesMenuItem.addEventListener("touchstart", handleMouseEnterOnServiceItem);
        document.addEventListener("touchstart", function(event) {
            if (!servicesDropdown.contains(event.target) && event.target !== servicesMenuItem) {
                handleMouseLeaveFromServiceItem();
            }
        });

        document.querySelectorAll(".services__item").forEach(function(element) {
            element.addEventListener("touchstart", function() {
                const departmentId = parseInt(this.getAttribute("data-department-id"), 10);
                const specialSlug = this.getAttribute("data-slug");
                showData.call(this, departmentId, specialSlug);
            });
            element.addEventListener("touchend", function() {
                this.querySelector(".services-list").innerHTML = "";
            });
        });
    }

    loadData();
    loadDepartments();
    initializeEventListeners();
});
